@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Montserrat', sans-serif;
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('../public/fonts/icomoon.eot?ppsprr');
  src: url('../public/fonts/icomoon.eot?ppsprr#iefix') format('embedded-opentype'),
    url('../public/fonts/icomoon.ttf?ppsprr') format('truetype'),
    url('../public/fonts/icomoon.woff?ppsprr') format('woff'),
    url('../public/fonts/icomoon.svg?ppsprr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.loader-circular {
  width: 38px;
  height: 38px;
  border: 3px solid #ffffff;
  border-bottom-color: #5e755a;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.9s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.datepicker-modal-container .datepicker-header {
  background-color: #5e755a !important;
}

.datepicker-footer-btn {
  color: #5e755a !important;
}

.datepicker-cell.selected .datepicker-cell-content {
  background-color: #5e755a !important;
}

.datepicker-cell.current .datepicker-cell-content {
  border: 1px solid #5e755a !important;
}

@layer components {
  .side-menu {
    background: linear-gradient(180deg, #f7fdf2 0%, #bdd3b8 100%);
    height: 100%;
    z-index: 1;
  }

  .logo {
    padding: 25% 0;
    display: block;
    text-align: center;
  }

  .side-menu::before {
    content: url('../public/img/bg-leaf.png');
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 346px;
    z-index: -1;
  }

  .list-menu {
    position: relative;
  }

  .list-menu::before {
    content: '\e908';
    width: 35px;
    display: inline-block;
    opacity: 0;
    font-family: 'icomoon';
    transition: all 150ms ease-in-out;
  }

  .list-menu:hover::before {
    opacity: 1;
  }

  .list-menu.menu-active::before {
    content: '\e908';
    opacity: 1;
  }

  .inner-menu {
    height: calc(100vh - 30vh);
  }

  .breadcrumb li {
    position: relative;
  }

  .breadcrumb li::after {
    content: '\e908';
    font-family: 'icomoon';
    display: inline-block;
    margin: 0 20px;
    font-weight: bold;
  }

  .breadcrumb li:last-child::after {
    display: none;
  }

  .btn-arrow::after {
    content: '\e904';
    font-size: 25px;
    font-family: 'icomoon';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .btn-arrow-create::after {
    content: '\e904';
    font-size: 25px;
    font-family: 'icomoon';
    position: absolute;
    right: 5px;
    top: 30px;
    z-index: 1;
  }

  .filtered::after {
    content: url('../public/img/icon/filtered.svg');
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-left: 10px;
  }

  .notif {
    transform: translate(-100%, 58px) !important;
    left: 56px !important;
  }

  .form-input {
    display: flex;
    flex-direction: column-reverse;
    transition: all 150ms ease-in-out;
  }

  .form-input input:focus + label {
    color: #748d6f;
  }

  .action li::after {
    content: '|';
    display: inline-block;
    margin: 0 15px;
    color: #d9d9d9;
  }

  .action li:last-child::after {
    display: none;
  }

  .step li {
    margin-bottom: 25px;
    padding-left: 40px;
    position: relative;
  }

  .step li::before {
    content: '';
    height: 100%;
    left: 11px;
    top: 24px;
    width: 2px;
    background: #748d6f;
    position: absolute;
  }

  .step li:last-child::before {
    display: none;
  }

  .bullet {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid #748d6f;
    left: 0;
  }

  .bullet::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #748d6f;
    left: 3px;
    top: 3px;
  }

  .arrow-down.collapsed {
    transform: rotate(90deg);
  }

  .arrow-down {
    transform: rotate(-90deg);
  }

  .drop-collapse.collapsed span {
    transform: rotate(0);
    transition: all 200ms ease-in-out;
  }

  .drop-collapse span {
    transform: rotate(-180deg);
    transition: all 200ms ease-in-out;
  }

  .input-number::-webkit-outer-spin-button,
  .input-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .input-number[type='number'] {
    -moz-appearance: textfield;
  }

  .btn-approve:hover img {
    filter: brightness(0) invert(1);
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #748d6f;
    border-color: #e7e7e7;
    font-weight: 600;
    border-left-width: 1px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-color: #fff;
  }

  .bg-login {
    background: linear-gradient(180deg, #f7fdf2 0%, #bdd4b9 100%);
    height: 100vh;
    background-size: cover;
    background-attachment: fixed;
  }

  .scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .scroll::-webkit-scrollbar {
    width: 3px;
    border-radius: 3px;
    margin-left: 5px;
  }

  .scroll::-webkit-scrollbar-track,
  .inner-menu::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  .scroll::-webkit-scrollbar-thumb {
    background: #e7e7e7;
  }
  .inner-menu::-webkit-scrollbar-thumb {
    background: #5e755a;
  }

  .collapse {
    visibility: visible !important;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-arrow-up-right:before {
    content: '\e92c';
  }
  .icon-watch:before {
    content: '\e92e';
  }
  .icon-settings:before {
    content: '\e900';
  }
  .icon-ico-analytic:before {
    content: '\e901';
  }
  .icon-ico-apply:before {
    content: '\e902';
  }
  .icon-ico-arrow-circle:before {
    content: '\e903';
  }
  .icon-ico-arrow-down:before {
    content: '\e904';
  }
  .icon-ico-arrow-up:before {
    content: '\e905';
  }
  .icon-ico-attachment:before {
    content: '\e906';
  }
  .icon-ico-calendar:before {
    content: '\e907';
  }
  .icon-ico-chev-right:before {
    content: '\e908';
  }
  .icon-ico-close:before {
    content: '\e909';
  }
  .icon-ico-close-circle:before {
    content: '\e90a';
  }
  .icon-ico-delete:before {
    content: '\e90b';
  }
  .icon-ico-download:before {
    content: '\e90c';
  }
  .icon-ico-edit:before {
    content: '\e90d';
  }
  .icon-ico-export:before {
    content: '\e90e';
  }
  .icon-ico-eye:before {
    content: '\e90f';
  }
  .icon-ico-filter:before {
    content: '\e910';
  }
  .icon-ico-help:before {
    content: '\e911';
  }
  .icon-ico-mminus:before {
    content: '\e912';
  }
  .icon-ico-notif:before {
    content: '\e913';
  }
  .icon-ico-pencil-edit:before {
    content: '\e914';
  }
  .icon-ico-phone:before {
    content: '\e915';
  }
  .icon-ico-plus:before {
    content: '\e916';
  }
  .icon-ico-plus-circle:before {
    content: '\e917';
  }
  .icon-ico-print:before {
    content: '\e918';
  }
  .icon-ico-product:before {
    content: '\e919';
  }
  .icon-ico-report:before {
    content: '\e91a';
  }
  .icon-ico-search:before {
    content: '\e91b';
  }
  .icon-ico-eye-close:before {
    content: '\e91c';
  }
  .icon-ico-sort:before {
    content: '\e91d';
  }
  .icon-ico-stock:before {
    content: '\e91e';
  }
  .icon-ico-store:before {
    content: '\e91f';
  }
  .icon-ico-upload:before {
    content: '\e920';
  }
  .icon-ico-user:before {
    content: '\e921';
  }
  .icon-ico-users:before {
    content: '\e922';
  }
  .icon-ico-watch:before {
    content: '\e923';
  }
  .icon-ico-bookmark:before {
    content: '\e924';
  }
  .icon-ico-info:before {
    content: '\e925';
  }
  .icon-ico-clock:before {
    content: '\e926';
  }
  .icon-arrow-up:before {
    content: '\e927';
  }
  .icon-arrow-down:before {
    content: '\e928';
  }
  .icon-ico-cube:before {
    content: '\e929';
  }
  .icon-ico-send:before {
    content: '\e92a';
  }
  .icon-ico-document:before {
    content: '\e92d';
  }
  .icon-ico-arrow-circle-right:before {
    content: '\e92b';
  }
}
