/* Button loading animation spinner */

.spinner {
  margin: 0px auto 0;
  width: 70px;
  text-align: center;
}
.spinner-colorful > div {
  background: linear-gradient(-90deg, #073649 0%, #457489 100%) !important;
  background: -webkit-linear-gradient(-90deg, #073649 0%, #457489 100%) !important;
}
.spinner > div {
  width: 11px;
  height: 11px;
  background-color: #fff;
  margin-right: 5px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Override the react-datepicker styles */

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background: #748d6f !important;
  border-radius: 0px;
}

.react-datepicker__current-month {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 20px;
  color: #748d6f;
}

.react-datepicker__header {
  background: #fff;
  border: none;
}

.react-datepicker {
  font-family: 'Montserrat', sans-serif;
  border-radius: 0px;
  border: 1px solid #ededed;
}
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  border-right-color: #748d6f;
  background-color: #eef4ea;
}
.react-datepicker__navigation--previous {
  left: 12px;
}
.react-datepicker__navigation--next {
  right: 12px;
}

.react-datepicker__navigation-icon--previous::before {
  right: -4px;
}
.react-datepicker__navigation-icon--next::before {
  left: -3px;
}
.react-datepicker__day,
.react-datepicker__day-name {
  margin: 0.37rem;
}
.react-datepicker__day-name {
  font-weight: 600;
}
.react-datepicker__day--in-selecting-range {
  background: #becbbb !important;
}
.react-datepicker__navigation-icon::before {
  border-color: #748d6f;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  position: absolute;
  top: 11px;
  width: 9px;
}
.react-datepicker__day--outside-month {
  color: #93a68f;
}

[data-slate-placeholder='true'] {
  width: 93% !important;
}

.react-datepicker__month-text {
  font-size: 0.9rem;
  font-weight: 500;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 5rem;
  margin: 5px;
  padding: 7px;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background: #748d6f;
  color: #fff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background: #748d6f;
  color: #fff;
}

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.react-datepicker__time-container {
  width: 150px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #748d6f;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  background-color: #748d6f;
}

.react-datepicker__header__dropdown .react-datepicker__header__dropdown--select {
  margin-top: 10px !important;
}
